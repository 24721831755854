export const photos = [
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0744.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0745.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0746.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0747.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0748.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0749.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0750.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0751.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0752.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0753.jpg`,
        width: 4,
        height: 3,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0754.jpg`,
        width: 4,
        height: 3,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0755.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0756.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0757.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0758.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0759.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0760.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0761.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0762.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0764.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0765.jpg`,
        width: 4,
        height: 3,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0768.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0769.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0770.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0771.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0772.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0774.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0776.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0777.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0778.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0779.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0780.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0781.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0782.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0783.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0784.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0785.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0786.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0787.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0788.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0789.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0790.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0791.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0792.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0793.jpg`,
        width: 3,
        height: 4,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0795.jpg`,
        width: 4,
        height: 3,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0796.jpg`,
        width: 4,
        height: 3,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0797.jpg`,
        width: 4,
        height: 3,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0798.jpg`,
        width: 4,
        height: 3,
    },
    {
        src: `${process.env.PUBLIC_URL}/images/gallery/IMG_0799.jpg`,
        width: 4,
        height: 3,
    },
];